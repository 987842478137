import { ENUM_TicketTypeId } from '~/enums'

export function generateGuestNumber(tagList: number[]) {
  // return `${oneOrZero(tagList, 1)}A${oneOrZero(tagList, 2)}C${oneOrZero(tagList, 3)}F_${oneOrZero(tagList, 4)}TO${oneOrZero(tagList, 5)}TM`
  const tags = [ENUM_TicketTypeId.Adult, ENUM_TicketTypeId.Child, ENUM_TicketTypeId.Family, ENUM_TicketTypeId.TicketOnly, ENUM_TicketTypeId.TicketMore]
  const suffixes = ['A', 'C', 'F_', 'TO', 'TM']
  return tags.map((tag, index) => `${oneOrZero(tagList, tag)}${suffixes[index]}`).join('')
}
function oneOrZero(tagList: number[], value: number): 0 | 1 {
  return Array.isArray(tagList) && tagList.length !== 0 && tagList.includes(value) ? 1 : 0
}
